<template>
  <b-collapse id="filtrosCollapse">
    <!-- Input to filter by campaign name -->
    <div class="d-flex align-items-center row">
      <div class="col-4 w-100">
        <label for="nome">Nome</label>
        <b-form-input
          v-model="filtroTemplateObj.nome"
          placeholder="Filtrar por nome do template"
          class="mr-2"
          @input="notificarAtualizacaoFiltros"
        ></b-form-input>
      </div>

      <div class="col-4 w-100">
        <label for="subconta">Status</label>
        <MultiSelect
          v-model="filtroTemplateObj.status"
          :options="opcoes.status"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          placeholder="Filtrar por status"
          class="w-100"
          @input="notificarAtualizacaoFiltros"
          optionLabel="name"
          optionValue="value"
        ></MultiSelect>
      </div>

      <div class="col-4 w-100">
        <label for="subconta">Contas</label>
        <MultiSelect
          v-model="filtroTemplateObj.subcontas"
          :options="opcoes.subcontas"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          placeholder="Filtrar por subconta"
          class="w-100"
          @input="notificarAtualizacaoFiltros"
          optionLabel="name"
          optionValue="value"
        ></MultiSelect>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MultiSelect from "primevue/multiselect";
import { mapGetters } from "vuex";

export default {
  name: "FiltrosTemplate",
  props: {
    value: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    MultiSelect
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "layoutConfig"])
  },
  data() {
    return {
      filtroTemplateObj: {
        nome: "",
        status: [
          "APPROVED",
          "IN_APPEAL",
          "PENDING",
          "REJECTED",
          "PENDING_DELETION",
          "DELETED",
          "DISABLED",
          "PAUSED",
          "LIMIT_EXCEEDED"
        ],
        subcontas: []
      },
      opcoes: {
        subcontas: [],
        status: [
          {
            name: "Aprovado",
            value: "APPROVED"
          },
          {
            name: "Em Análise",
            value: "IN_APPEAL"
          },
          {
            name: "Pendente",
            value: "PENDING"
          },
          {
            name: "Rejeitado",
            value: "REJECTED"
          },
          {
            name: "Remoção Pendente",
            value: "PENDING_DELETION"
          },
          {
            name: "Removido",
            value: "DELETED"
          },
          {
            name: "Desabilitado",
            value: "DISABLED"
          },
          {
            name: "Pausado",
            value: "PAUSED"
          },
          {
            name: "Limitadp",
            value: "LIMIT_EXCEEDED"
          }
        ]
      }
    };
  },
  methods: {
    setarFiltroDeReset() {
      this.filtroTemplateObj = {
        nome: "",
        status: [
          "APPROVED",
          "IN_APPEAL",
          "PENDING",
          "REJECTED",
          "PENDING_DELETION",
          "DELETED",
          "DISABLED",
          "PAUSED",
          "LIMIT_EXCEEDED"
        ],
        subcontas: this.opcoes.subcontas.map((c) => c.value)
      };
      this.notificarAtualizacaoFiltros();
    },
    notificarAtualizacaoFiltros() {
      this.$emit("input", this.filtroTemplateObj);
    },
    async pegarContaSubContas() {
      const subcontas = await ApiService.get(
        "/administrativo/conta",
        "",
        false
      );
      this.opcoes.subcontas = subcontas.data.subContas.map((v) => {
        return {
          name: v.con_nome_identificacao ?? v.con_login,
          value: v.conta_id
        };
      });
      const contaPrincipalId = subcontas.data?.contaPrincipal?.conta_id;
      this.opcoes.subcontas.unshift({
        value: contaPrincipalId,
        name: "Conta Principal"
      });
      this.opcoes.subcontas.push({
        value: this.currentUserPersonalInfo.conta_id,
        name: this.currentUserPersonalInfo.conta
      });
      this.conta = contaPrincipalId;
      this.setarFiltroDeReset();
    }
  },
  mounted() {
    this.pegarContaSubContas();
  }
};
</script>

<style lang="scss" scoped>
#filtrosCollapse {
  padding: 0.5rem 1.5rem;
}
</style>
